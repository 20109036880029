import React from "react"
import { graphql } from "gatsby"

import { LandingMain, LandingContent } from "../components/LandingPage"
import { Footer } from "../components/Footer"
import { Subscribe } from "../components/Subscribe"
import { Layout } from "../components/atoms"
import SEO from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const firstPost = data.firstPost.edges[0]
  const posts = data.allPosts.edges

  return (
    <Layout>
      <SEO
        title="All Posts"
        image={firstPost.node.frontmatter.mainImage}
        relativeUrl="/"
      />
      <LandingMain
        to={firstPost.node.fields.slug}
        title={firstPost.node.frontmatter.title}
        mainImage={firstPost.node.frontmatter.mainImage}
      />
      <LandingContent posts={posts} />
      <Subscribe />
      <Footer scrollToId="landing-main" />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    firstPost: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 1
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            author
            mainImage {
              childImageSharp {
                fluid(quality: 100, maxWidth: 4160) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    allPosts: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      skip: 1
    ) {
      edges {
        node {
          excerpt(pruneLength: 126)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            author
            mainImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
