import React from "react"

import { LandingContentItem } from "./LandingContentItem"

import "./LandingContent.scss"

const LandingContent = ({ posts }) => {
  return (
    <div className="LandingContent" id="landing-content">
      <div className="LandingContent__ContentItems">
        {posts.map(({ node }) => (
          <LandingContentItem
            key={node.fields.slug}
            id={node.frontmatter.title}
            to={node.fields.slug}
            mainImage={node.frontmatter.mainImage}
            title={node.frontmatter.title}
            shortText={node.excerpt}
            author={node.frontmatter.author}
            time={node.frontmatter.date}
          />
        ))}
      </div>
    </div>
  )
}

export default LandingContent
