import React from "react"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { scroller } from "react-scroll"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import { Arrow, OutlineButton } from "../../atoms"

import "./LandingMain.scss"

import inappLogo from "../../../../content/assets/logo.png"

const LandingMain = ({ to, title, mainImage }) => {
  const isSmaller = useMediaQuery("(max-width:920px)")

  const scrollToContent = () => {
    scroller.scrollTo("landing-content", {
      duration: 1000,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: 1,
    })
  }

  const handleLogoClick = () => {
    trackCustomEvent({
      category: "Logo click",
      action: "Click On Landing Page Logo",
    })
  }

  return (
    <div className="LandingMain" id="landing-main">
      <BackgroundImage
        className="LandingMain__MainImage"
        fluid={mainImage.childImageSharp.fluid}
      >
        <div className="LandingMain__MainImage__Wrapper">
          <div className="LandingMain__MainImage__Wrapper__Header">
            <a href="https://www.inappsystems.com/" onClick={handleLogoClick}>
              <img
                className="LandingMain__MainImage__Wrapper__Header__Logo"
                src={inappLogo}
                alt="InApp Systems logo"
              />
            </a>
          </div>
          <div className="LandingMain__MainImage__Wrapper__Details">
            <p className="LandingMain__MainImage__Wrapper__Details__LatestText">
              Latest post
            </p>
            <h1 className="LandingMain__MainImage__Wrapper__Details__Title">
              {title}
            </h1>
            <Link to={to}>
              <OutlineButton
                label="Read More"
                fontSize={!isSmaller ? "1.7rem" : "1.6rem"}
                width={!isSmaller ? "240px" : "220px"}
                height={!isSmaller ? "64px" : "60px"}
                fontWeight="500"
              />
            </Link>
          </div>
          <div className="LandingMain__MainImage__Wrapper__Scroll">
            <Arrow onClick={scrollToContent} />
          </div>
        </div>
      </BackgroundImage>
    </div>
  )
}

export default LandingMain
